/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import { useLocation, useMatch } from '@reach/router';
import theme from '@theme';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';

import {
  Footer,
  Modal,
  PageHeader,
  TemplatedContent,
  ChatSatisfi,
  ChatTrackhs,
  Link,
  OverlayWrapper,
} from '@powdr/components';
import {
  Breakpoints, Components, Level, QueryParamNames, Season,
  DrupalDynamicContentTypes, ThemeProps as Prop,
  GlobalLayoutValues,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useWindowResize, useSeasonChange } from '@powdr/hooks';
import {
  componentFactory,
  // convertStaticTheme,
  // convertConfig,
  // convertTheme,
  indexByColorProfile,
  isEmpty,
  componentColor,
  getFontStyles,
} from '@powdr/utils';
import Popup from '@powdr/web/src/components/popup';
import { SEO, DynamicSEO } from '@powdr/web/src/components/seo';
import { Tracking } from '@powdr/web/src/components/tracking';
import { useStaticData } from '@powdr/web/src/hooks';
import GlobalStyle from '@powdr/web/src/utils/global-styles';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

const templatedContentQueryMap = {
  [DrupalDynamicContentTypes.EVENT]: 'eventContent',
  [DrupalDynamicContentTypes.BLOG]: 'blogContent',
  [DrupalDynamicContentTypes.NEWS]: 'newsContent',
  [DrupalDynamicContentTypes.PRESS_RELEASE]: 'pressReleaseContent',
  [DrupalDynamicContentTypes.RESTAURANT]: 'restaurantContent',
  [DrupalDynamicContentTypes.ACTIVITY]: 'activityContent',
  [DrupalDynamicContentTypes.SPECIALS]: 'specialsContent',
  [DrupalDynamicContentTypes.LESSON]: 'lessonContent',
  [DrupalDynamicContentTypes.FACILITY]: 'facilityContent',
};

const SiteWrapper = styled.div``;

const StyledInnerPage = styled.div``;

const StyledMain = styled.main`
  ${({ $removeWrapperPadding, $stickyNavHeight }) => (!$removeWrapperPadding)
    && `padding-top: ${GlobalLayoutValues.DESKTOP.HEADER_HEIGHT.ACTIVE + $stickyNavHeight}px;
  `};

  ${Breakpoints.mobile(css`
    ${({ $removeWrapperPadding, $stickyNavHeight }) => (!$removeWrapperPadding)
      && `padding-top: ${GlobalLayoutValues.MOBILE.HEADER_HEIGHT.ACTIVE + $stickyNavHeight}px;
    `};
  `)}
`;

const SkipToNavigation = styled.nav`
  display: flex;
  background-color: ${() => componentColor(Prop.BACKGROUND, Components.ALERT_BAR, Prop.BACKGROUND)};
  padding: 0 25px;
  gap: 25px;
  height: 0;
  position: static;

  a {
    font-size: 0;
  }

  &:focus-within {
    height: fit-content;

    a {
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
      margin: 2px;
      padding: 10px 5px;
      height: fit-content;
    }
  }
`;

const SkipToButton = styled(Link)`
  height: 0;
  color: ${() => componentColor(Prop.LINK_TXT, Components.ALERT_BAR, Prop.LINK_TXT)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};

  &:focus, &:hover {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, Components.ALERT_BAR, Prop.LINK_TXT_HOVER)};
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, Components.ALERT_BAR, Prop.LINK_TXT_HOVER)};
  }
`;

const VideoModalPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const FrameWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 75%;
  padding-bottom: 42.1875%;

  .video-block-modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function Layout({
  data,
  uri,
  pageContext,
}) {
  const [seasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);
  const [pageParam, setPageParam] = useQueryParam(QueryParamNames.PAGE, NumberParam);
  const [videoParam, setVideoParam] = useQueryParam(QueryParamNames.VIDEO, StringParam);
  const resize = useWindowResize();
  const {
    defaultSeason,
    chatComponent,
    footerComponents,
  } = useStaticData();
  const {
    themeConfig,
    defaultTheme,
    fullHeaderHeight,
    handleTheme,
    isQrQueryCardPageActive,
    setIsCancelScroll,
    setIsMobile,
    setQrQueryCardPageActive,
    singleItemView,
    uiSeason,
    setIsWoodwardColorTakeover,
    secondLevelNavHeight,
  } = useContext(AppContext);
  const [isHeroOverride, setIsHeroOverride] = useState(false);
  const [drupalViewSingleItem, setDrupalViewSingleItem] = useState({});
  const isPageUsesWoodwardTheme = data.pageContent.nodes.some(
    (node) => node.isWoodwardColorTakeover,
  );
  const { nodeType, hasHeroAsFirstComponent } = pageContext;
  const { handleSeasonChange } = useSeasonChange();
  const { pathname, origin, href } = useLocation();
  const isStaticTemplatedContent = Object.values(DrupalDynamicContentTypes).includes(nodeType);
  const templatedContentType = templatedContentQueryMap?.[nodeType] || null;
  const templatedContentData = data?.[templatedContentType]?.nodes?.[0] || null;
  const match = useMatch(
    `${pathname.slice(0, pathname.lastIndexOf('/'))}/:item`,
  );
  const siteWrapperRef = useRef();

  if (!data || !theme) return null;

  useLayoutEffect(() => {
    // Site Spinup Utilities
    // console.log('Remove the following function calls unless converting powdr-ui site');
    // convertStaticTheme();
    // convertTheme();
    // convertConfig();

    setIsWoodwardColorTakeover(isPageUsesWoodwardTheme);
    // Skip season logic if site doesn't use seasonality & current page isn't a WW takeover page
    if (!defaultSeason) return;
    if (!uiSeason || (defaultSeason === Season.SUMMER && !seasonParam && pathname === '/')) {
      // homepage with default summer override default
      handleSeasonChange(seasonParam === Season.SUMMER ? Season.SUMMER : defaultSeason);
    } else if (!uiSeason || (defaultSeason === Season.SUMMER && seasonParam === Season.WINTER && pathname === '/')) {
      // homepage with default summer has winter season param
      handleSeasonChange(Season.WINTER);
    }
    // Don't add dependencies, we only want this to fire on first homepage load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname !== '/') {
      // non homepage use param only
      handleTheme(
        (seasonParam === Season.SUMMER
          ? Season.SUMMER
          : Season.WINTER
        ), isPageUsesWoodwardTheme,
      );
    } else {
      // handle when theme doesn't get set
      handleTheme(defaultSeason, isPageUsesWoodwardTheme);
    }
  }, [isPageUsesWoodwardTheme, pathname, defaultSeason, handleTheme, seasonParam]);

  /* Setting isMobile context variable */
  useEffect(() => {
    setIsMobile(!!(resize.windowWidth < 960));
  }, [resize.windowWidth, setIsMobile]);

  const levels = useMemo(
    () => uri.split('/').filter((level) => level !== ''),
    [uri],
  );

  // Create Map() of pages to access pages by name rather than item in array
  const pages = useMemo(
    () => new Map(
      // Sort by length of page route so levels are in order
      data.pageContent.nodes
        .sort((a, b) => a.pageLocation.length - b.pageLocation.length)
        .reduce((allPages, page) => {
          // Merge taxonomy term settings with page settings
          allPages.push({
            ...page,
            ...data.pageSettings.nodes.find(
              (settings) => settings.link === page.pageLocation,
            ),
          });
          return allPages;
          // If there are no levels but there is content it's level0, else regular level
          // TODO: Check structure of level 1 only pages
        }, [])
        .map((page, id) => (!levels.length && page
          ? [Level.LEVEL_0, page]
          : [`level${id + 1}`, page])),
    ),
    [data, levels.length],
  );

  const currentLevelTwo = useMemo(() => Array.from(pages.values())[1], [pages]);
  const currentPage = useMemo(
    () => Array.from(pages.values()).pop() || {},
    [pages],
  );
  const parentPage = useMemo(
    () => Array.from(pages.values())[levels.length - 2] || {},
    [levels.length, pages],
  );
  const title = useMemo(
    () => currentLevelTwo?.displayTitle || currentLevelTwo?.title,
    [currentLevelTwo?.displayTitle, currentLevelTwo?.title],
  );

  useEffect(() => {
    setIsCancelScroll(
      parentPage?.isOpenChildrenInModals
      || (currentPage?.isOpenChildrenInModals),
    );
  }, [currentPage, parentPage, setIsCancelScroll]);

  // TODO: may be able to refactor QR Query card component so that this
  // isn't necessary (possibly via utilizing modal component within the component)
  /* Handle special layout case for QR Query Card component */
  useEffect(() => {
    pages.get(Level.LEVEL_2)?.relationships.pageContent.forEach((component) => {
      if (component.isL1HeroOverride) {
        setIsHeroOverride(true);
      }
    });

    // Check if page should be handled for QR Query Card component
    if (
      pages
        .get(Level.LEVEL_1)
        ?.relationships.pageContent.map(
          (component) => component.typeName === Components.QR_QUERY_CARDS,
        )
        .includes(true)
    ) {
      setQrQueryCardPageActive(true);
    } else {
      setQrQueryCardPageActive(false);
    }
  }, [pages, setQrQueryCardPageActive]);

  // Check for level 1 hero(es)
  const isHeroTop = useCallback(
    (idx, componentName) => !!(
      (idx === 0 || idx === 1)
        && componentName === Components.CONTENT_BLOCK_HERO
    ),
    [],
  );

  // TODO: Create some slightly smarter logic to check if any page has hero and
  // place it at the top of the page to make the stickynav logic a bit simpler
  const showInnerContent = pages.get(Level.LEVEL_1)?.link !== currentPage?.link;

  /* const innerContentTop = useMemo(
    () => ((levels.length > 1 && !isHeroOverride)
      ? pages
        .get(Level.LEVEL_1)
        ?.relationships.pageContent.filter((component, idx) => isHeroTop(idx, component.typeName))
      : pages
        .get(Level.LEVEL_2)
        ?.relationships.pageContent.filter(
          (component, idx) => isHeroTop(idx, component.typeName),
        ) || []),
    [isHeroOverride, isHeroTop, levels, pages],
  ); */
  const innerContentTop = ((levels.length > 1 && !isHeroOverride)
    ? pages
      .get(Level.LEVEL_1)
      ?.relationships.pageContent.filter((component, idx) => isHeroTop(idx, component.typeName))
    : pages
      .get(Level.LEVEL_2)
      ?.relationships.pageContent.filter(
        (component, idx) => isHeroTop(idx, component.typeName),
      ) || []);

  const innerContentBottom = (levels.length > 1)
    ? pages.get(Level.LEVEL_1)?.relationships.pageContent.filter(
      (component, idx) => !isHeroTop(idx, component.typeName),
    ) || []
    : [];

  // eslint-disable-next-line max-len
  /* const showStickyNavInPageHeader = useMemo(
    () => (hasHeroAsFirstComponent && showInnerContent && innerContentTop.length > 0)
  || (!hasHeroAsFirstComponent && showInnerContent && innerContentTop.length > 0),
    [hasHeroAsFirstComponent, showInnerContent, innerContentTop],
  );
  const showStickyNavAttachedToMainHeader = useMemo(
    () => showInnerContent && innerContentTop.length === 0 && pathname !== '/',
    [showInnerContent, innerContentTop, pathname],
  ); */

  const showStickyNavInPageHeader = (hasHeroAsFirstComponent
    && showInnerContent
    && innerContentTop.length > 0)
  || (!hasHeroAsFirstComponent && showInnerContent && innerContentTop.length > 0);
  const showStickyNavAttachedToMainHeader = showInnerContent && innerContentTop.length === 0 && pathname !== '/';

  const { drupal } = useSelector((state) => state.drupal) || [];

  /* Handle templated dynamic content (events, blogs, etc.) loaded via API */
  useEffect(() => {
    const singleItemViewName = (!singleItemView || !match?.item) ? '' : `${singleItemView}_${match?.item}`;
    if (!isEmpty(drupal?.[singleItemViewName])) {
      setDrupalViewSingleItem(drupal[`${singleItemView}_${match?.item}`] || {});
    } else {
      setDrupalViewSingleItem({});
    }
  }, [drupal, match?.item, singleItemView]);

  const isTemplatedContentModal = useMemo(
    () => !!(
      (currentPage?.isOpenChildrenInModals && !isEmpty(drupalViewSingleItem))
      || (isStaticTemplatedContent && currentPage?.isOpenChildrenInModals)
    ),
    [currentPage.isOpenChildrenInModals, drupalViewSingleItem, isStaticTemplatedContent],
  );

  const isNonTemplatedContentModal = useMemo(
    () => !!(parentPage?.isOpenChildrenInModals && parentPage.id !== currentPage.id),
    [parentPage.id, currentPage.id, parentPage?.isOpenChildrenInModals],
  );

  /* Handle templated content loaded via API or statically */
  const templatedContent = useMemo(() => {
    /* static templated content */
    if (isStaticTemplatedContent && templatedContentType) {
      return (
        <TemplatedContent
          fields={templatedContentData}
          isParentModal={isTemplatedContentModal}
        />
      );
    }

    /* dynamic templated content */
    if (!isEmpty(drupalViewSingleItem)) {
      return (
        <TemplatedContent
          fields={drupalViewSingleItem}
          isParentModal={isTemplatedContentModal}
        />
      );
    }

    return null;
  }, [
    drupalViewSingleItem, isStaticTemplatedContent, templatedContentType,
    templatedContentData, isTemplatedContentModal,
  ]);

  const queryPageData = useMemo(
    () => ((pageParam)
      ? data.allGloballyAccessiblePages.nodes.find((obj) => obj.nodeID === pageParam)
      : null),
    [pageParam, data],
  );

  const trackableProducts = useMemo(
    () => currentPage?.relationships?.trackableProducts || [],
    [currentPage],
  );

  const isPageModal = useMemo(
    () => (isTemplatedContentModal
    || isNonTemplatedContentModal
    || queryPageData
    || videoParam),
    [isTemplatedContentModal,
      isNonTemplatedContentModal,
      queryPageData,
      videoParam],
  );

  /* TODO: Fix no-scroll on body */
  /* useEffect(() => {
    if (siteWrapperRef) {
      if (isDisablePageScroll) {
        siteWrapperRef.current.setAttribute('inert', '');
      } else {
        siteWrapperRef.current.removeAttribute('inert');
      }
    }
  }, [isDisablePageScroll]); */

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      {/* SEO for Dynamic Pages Only */}
      {(!!(!isEmpty(drupalViewSingleItem) ? drupalViewSingleItem : templatedContentData)) && (
        <DynamicSEO
          title={title}
          url={origin + pathname}
          href={href}
          currentPage={currentPage}
          levels={levels}
          pages={pages}
          defaultSeason={defaultSeason}
          dynamic={!isEmpty(drupalViewSingleItem) ? drupalViewSingleItem : templatedContentData}
        />
      )}
      <Tracking
        trackableProducts={trackableProducts}
      />
      <SkipToNavigation aria-label="Accessibility Skip-To Navigation">
        <SkipToButton href="#footer">Skip to Footer Navigation</SkipToButton>
      </SkipToNavigation>
      <SiteWrapper
        ref={siteWrapperRef}
      >
        {!isQrQueryCardPageActive && (
          <OverlayWrapper
            levels={levels}
            showStickyNav={showStickyNavAttachedToMainHeader}
            isPageModal={isPageModal}
          />
        )}
        <StyledMain
          id="main-content"
          $isQRQueryCardPage={isQrQueryCardPageActive}
          $removeWrapperPadding={showStickyNavInPageHeader || pathname === '/' || (!showStickyNavInPageHeader && !showStickyNavAttachedToMainHeader)}
          $stickyNavHeight={secondLevelNavHeight}
          /* ref={(node) => node && (isDrawerOpen
            ? node.setAttribute('inert', '')
            : node.removeAttribute('inert')
          )} */
        >
          {
            // Content but no levels? must be homepage
            !!pages.get(Level.LEVEL_0)
              && pathname === '/'
              && componentFactory(
                pages.get(Level.LEVEL_0).relationships.pageContent,
              )
          }
          {
            // All inner pages
            !!levels.length && (
              <StyledInnerPage isBackButton={parentPage?.isShowBackBtnOnChildren}>
                {/* Eg. Level 1 top unless Level 1 only page */}
                {(showInnerContent)
                  && componentFactory(innerContentTop, null, null, { isL1HeroOverride: false })}

                {/* Hand holding the inner page content */}
                {(!isQrQueryCardPageActive && !currentPage.isLandingPage) && (
                  <PageHeader
                    // !TODO: If dynamic this back button link needs to come from currentPage
                    backButtonLink={
                      parentPage?.isShowBackBtnOnChildren
                        ? parentPage?.link
                        : null
                    }
                    headerHeight={fullHeaderHeight || 0}
                    levels={levels}
                    title={!currentPage.isHidePageTitle ? title : ''}
                    showStickyNav={showStickyNavInPageHeader}
                  />
                )}

                {/* If non-dynamic content loads in modal, load parent content behind */}
                {parentPage?.isOpenChildrenInModals
                  && componentFactory(parentPage?.relationships?.pageContent || [])}

                {/* If dynamic content loads in modal, load parent content behind */}
                {currentPage?.isOpenChildrenInModals
                  && isTemplatedContentModal
                  && componentFactory(
                    currentPage?.relationships?.pageContent || [],
                  )}

                {/* If templated content without modal, load as content */}
                {(!parentPage?.isOpenChildrenInModals
                  && !currentPage?.isOpenChildrenInModals
                  && !isTemplatedContentModal)
                  && templatedContent}

                {/* If non-templated content without modal, load as content (standard page) */}
                {!parentPage?.isOpenChildrenInModals
                  && !templatedContent
                  && componentFactory(
                    currentPage?.relationships?.pageContent || [],
                  )}
                {/* End of Hand holding the inner page content */}

                {/* Eg. Level 1 bottom unless Level 1 only page */}
                {(showInnerContent) && componentFactory(innerContentBottom)}
              </StyledInnerPage>
            )
          }
        </StyledMain>
        {/* Additional Footer Components */}
        {(!isQrQueryCardPageActive && footerComponents) && (
          componentFactory(
            footerComponents,
          )
        )}
        {!isQrQueryCardPageActive && (
          <Footer
            id="footer"
            partners={data.allTaxonomyTermPartners.nodes}
            footerLinks={data.allTaxonomyTermFooterLinks.nodes}
            colorProfile={themeConfig?.[Components.FOOTER]}
          />
        )}
        {gatsbyConfig?.enableBrightEdgeTracking && (
          <div className="be-ix-link-block" />
        )}

        {/* Chat Component widgets */}
        {chatComponent.hasChatComponent && (
          <>
            {!isEmpty(chatComponent.chatSatisfi) && (
              <ChatSatisfi
                {...chatComponent.chatSatisfi}
              />
            )}
            {!isEmpty(chatComponent.chatTrackhs) && (
              <ChatTrackhs
                {...chatComponent.chatTrackhs}
              />
            )}
          </>
        )}
      </SiteWrapper>

      {/* Dynamic data modal */}
      {isTemplatedContentModal && (
        <Modal closeModalLink={currentPage.link}>
          {templatedContent}
        </Modal>
      )}

      {/* Non-dynamic page modal */}
      {isNonTemplatedContentModal && (
        <Modal closeModalLink={parentPage.link}>
          {componentFactory(
            currentPage?.relationships?.pageContent || [],
            indexByColorProfile(themeConfig?.[Components.MODAL]),
          )}
        </Modal>
      )}

      {/* Query parameter modal */}
      {queryPageData && (
        <Modal handleCloseModal={() => { setPageParam(undefined); }}>
          {componentFactory(
            queryPageData?.relationships?.pageContent || [],
            indexByColorProfile(themeConfig?.[Components.MODAL]),
          )}
        </Modal>
      )}

      {/* Video modal */}
      {videoParam && (
        <Modal handleCloseModal={() => { setVideoParam(undefined); }} isFullScreen>
          <VideoModalPositionWrapper>
            <FrameWrapper>
              <iframe
                title="modal-embedded-video"
                className="video-block-modal-frame"
                src={`https://www.youtube.com/embed/${videoParam}?autoplay=1&rel=0&wmode=transparent&modestbranding=1&autohide=1&showinfo=0`}
                allow="autoplay"
                allowFullScreen
              />
            </FrameWrapper>
          </VideoModalPositionWrapper>
        </Modal>
      )}
      {/* Popup Form */}
      <Popup />
    </>
  );
}

Layout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  pageContext: PropTypes.instanceOf(Object).isRequired,
  uri: PropTypes.string.isRequired,
};

export default Layout;

export const query = graphql`
  query LayoutQuery($nestedPaths: [String], $fullPath: String) {
    pageContent: allNodePage(
      filter: { field_page_location: { in: $nestedPaths }, status: { eq: true } }
    ) {
      ...NodePageQuery
    }
    activityContent: allNodeActivity(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeActivityQuery
    }
    blogContent: allNodeBlog(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeBlogQuery
    }
    eventContent: allNodeEvent(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeEventQuery
    }
    newsContent: allNodeNews(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeNewsQuery
    }
    pressReleaseContent: allNodePressRelease(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodePressReleaseQuery
    }
    restaurantContent: allNodeRestaurant(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeRestaurantQuery
    }
    specialsContent: allNodeSpecials(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeSpecialsQuery
    }
    lessonContent: allNodeLesson(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }, field_no_build: { ne: true }}) {
      ...NodeLessonQuery
    }
    facilityContent: allNodeFacilityItem(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true }}) {
      ...NodeFacilityQuery
    }
    allGloballyAccessiblePages: allNodePage(filter: { field_globally_accessible: { eq: true }, status: { eq: true } }) {
      ...NodePageQuery
    }
    allTaxonomyTermFooterLinks {
      ...TaxonomyTermFooterLinksQuery
    }
    allTaxonomyTermPartners {
      ...TaxonomyTermPartnersQuery
    }
    paragraphHeaderConfiguration {
      ...HeaderConfigurationQuery
    }
    pageSettings: allTaxonomyTermSiteNavigation(
      filter: { field_navigation_link: { in: $nestedPaths } }
    ) {
      nodes {
        ...NavigationItemQuery
      }
    }
  }
`;

export const Head = ({
  location, data,
}) => {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const pageContent = dataCopy?.pageContent?.nodes;

  /* Handle static templates here */
  Object.entries(dataCopy).forEach(([k, v]) => {
    if (k.includes('Content')) {
      v.nodes.forEach((node) => pageContent.push(node));
    }
  });

  return (
    <SEO
      parentPages={pageContent.slice(0, pageContent.length - 1)}
      currentPageData={pageContent[pageContent.length - 1]}
      location={location}
    />
  );
};
