/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';

import { Modal } from '@powdr/components';
import { componentFactory } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

// eslint-disable-next-line import/no-dynamic-require

export const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { pathname } = useLocation();
  const popupTimeoutRef = useRef();
  const popupDelay = 8000; // ms
  const popupLocalStorageKey = `${process.env.GATSBY_PROPERTY_NAME}-popup-form-last-version-seen`;

  const {
    popupForm,
  } = useStaticData();

  const handleShowPopup = () => {
    const userLocalPopupState = localStorage.getItem(popupLocalStorageKey);

    // if user has no alert state in their local storage
    if (!userLocalPopupState) {
      setShowPopup(true);
    // if user has an alert state in their local storage
    } else {
      const localData = JSON.parse(userLocalPopupState);

      // if the last alert put into local storage does not match the new one
      if (localData !== popupForm?.version) {
        setShowPopup(true);
        localStorage.removeItem(popupLocalStorageKey);
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem(popupLocalStorageKey, popupForm.version);
  };

  const disablePopupPageCheck = () => !popupForm?.relationships?.disabledPages
    ?.map((p) => p.path)
    ?.includes(pathname);

  /* handle timed popup */
  useEffect(() => {
    popupTimeoutRef.current = setTimeout(() => {
      if (popupForm
        && popupForm?.formName
        && disablePopupPageCheck()
      ) {
        handleShowPopup();
      }
    }, popupDelay);

    return () => {
      clearTimeout(popupTimeoutRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (popupForm?.disabled) return null;

  return (
    showPopup && (
      <Modal
        handleCloseModal={handleClosePopup}
        isMiniModal
        removeScroller
      >
        {componentFactory(
          [popupForm],
          undefined,
          undefined,
          { passthroughFunc: handleClosePopup },
        )}
      </Modal>
    )
  );
};

Popup.propTypes = {};

Popup.defaultProps = {};
